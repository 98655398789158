import { useState } from "preact/hooks";
import "@albelli/arc-3/lib/alert.css";
import { Alert } from "@albelli/arc-3-react/lib/Alert";

import { DragSwitch } from "react-dragswitch";
import "react-dragswitch/dist/index.css";
import Emoji from "react-emoji-render";

import styles     from "./aboutYou.scss";
import { Button } from '@albelli/arc-3-react/lib/Button';

export default function AboutYou() {
    const [checkedProactive, setCheckedProactive] = useState(false);
    const [checkedSenior, setCheckedSenior] = useState(false);
    const [checkedCare, setCheckedCare] = useState(false);
    const [checkedSmort, setCheckedSmort] = useState(false);
    const disableApplyButton = !checkedProactive || !checkedSenior || !checkedCare || !checkedSmort;
    return (
        <div className={styles.aboutYou}>
            <h2>About you</h2>

            {checkedProactive && checkedSenior && checkedCare && checkedSmort ? (
                <Alert success>
                    <Emoji text="😍 You're awesome!" />{" "}
                    <a
                        class="arc3-text--color-brand-white"
                        href="https://albelli-careers.com/vacancies/senior-full-stack-engineer"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Please apply here!
                    </a>
                </Alert>
            ) : checkedProactive || checkedSenior || checkedCare || checkedSmort ? (
                <Alert warning>
                    <Emoji text="😮 maybe you do..." />
                </Alert>
            ) : (
                    <Alert>
                        <Emoji text="🤔...do you have what it takes?" />
                    </Alert>
                )}

            <label className="arc3-flex-wrapper --flex-direction-row --justify-content-space-between">
                <div className={styles.checkpointLabel}>You are proactive and pragmatic</div>
                <DragSwitch
                    checked={checkedProactive}
                    onChange={(e) => {
                        setCheckedProactive(e);
                    }}
                />
            </label>
            <hr />
            <label className="arc3-flex-wrapper --flex-direction-row --justify-content-space-between">
                <div className={styles.checkpointLabel}>You are a senior Full stack engineer</div>
                <DragSwitch
                    checked={checkedSenior}
                    onChange={(e) => {
                        setCheckedSenior(e);
                    }}
                />
            </label>
            <hr />

            <label className="arc3-flex-wrapper --flex-direction-row --justify-content-space-between">
                <div className={styles.checkpointLabel}>You care about everything from the technical design, the code,
                build pipeline to the infrastructure
                </div>
                <DragSwitch
                    checked={checkedCare}
                    onChange={(e) => {
                        setCheckedCare(e);
                    }}
                />
            </label>
            <hr />
            <label className="arc3-flex-wrapper --flex-direction-row --justify-content-space-between">
                <div className={styles.checkpointLabel}>You are hella smort</div>
                <DragSwitch
                    checked={checkedSmort}
                    onChange={(e) => {
                        setCheckedSmort(e);
                    }}
                />
            </label>
            <hr />
            <div className={`arc3-typo--text-center`}>
                <Button disabled={disableApplyButton}
                    href={ disableApplyButton ? '#' : 'https://albelli-careers.com/vacancies/senior-full-stack-engineer'}
                    target={ disableApplyButton ? '' : '_blank'}
                >
                    Apply
                </Button>
            </div>
        </div>
    );

    // const [checked, setChecked] = useState(true);
    // return (
    //     <div>
    //         <h2>About you</h2>
    //         <p>You are a proactive and pragmatic person, that is always looking to improve in your field. As a senior full stack engineer, you will be designing, building and maintaining our stack together with the rest of the team.

    //             To do this you will have the space to come up with new solutions, research and propose new technologies. You care about everything from the technical design, the code, build pipeline to the infrastructure.

    //             Is there something we can do better? Help us do so! </p>
    //         <div className="arc3-checkbox-wrapper">
    //             <div className="arc3-checkbox">
    //                 <CheckBox id="check" onChange={() => setChecked(!checked)} value={checked}></CheckBox>
    //             </div>
    //             You are proactive and pragmatic
    //         </div>
    //         <div className="arc3-checkbox-wrapper">
    //             <div className="arc3-checkbox">
    //                 <CheckBox id="check" onChange={() => setChecked(!checked)} value={checked}></CheckBox>
    //             </div>
    //             You are a senior Full stack engineer
    //         </div>
    //         <div className="arc3-checkbox-wrapper">
    //             <div className="arc3-checkbox">
    //                 <CheckBox id="check" onChange={() => setChecked(!checked)} value={checked}></CheckBox>
    //             </div>
    //             You care about everything from the technical design, the code, build pipeline to the infrastructure
    //         </div>
    //         <div className="arc3-checkbox-wrapper">
    //             <div className="arc3-checkbox">
    //                 <CheckBox id="check" onChange={() => setChecked(!checked)} value={checked}></CheckBox>
    //             </div>
    //             You are hella smort
    //         </div>
    //     </div>
    // );
}
