import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper.scss';
import "swiper/components/navigation/navigation.min.css"
import "swiper/components/pagination/pagination.min.css"

// import Swiper core and required modules
import SwiperCore, {
    Pagination
} from 'swiper/core';

// install Swiper modules
SwiperCore.use([Pagination]);

export default function Slider() {
    return (
        <>
            <Swiper
                spaceBetween={0}
                slidesPerView={1}
                loop={true}
                pagination={{
                    clickable: true
                }}
            >
                {[1,2,3].map((index) => (
                    <SwiperSlide key={index}>
                        <img style="width: 100%;" src={`./assets/imgs/team-pic-${index}.jpg`} />
                    </SwiperSlide>
                    ))}
            </Swiper>
        </>
    );
}
