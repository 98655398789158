import "@albelli/arc-3/lib/buttons.css";

import styles from "./intro.scss";

export default function Intro() {
    return (
        <div class="arc3-flex-wrapper">
            <div class={styles.hello}>
                <h1>The albelli Storefront team is looking for a senior full
                    stack developer!</h1>
                <p>
                    Hi there! Our team is looking for a new member!
                </p>
                <p>
                    Our team builds, maintains and improves multiple applications and microservices to create great customer experiences on 8 high-traffic, multi-brand <a href="https://www.bonusprint.co.uk" target="_blank">websites</a>. We also automate (repetitive) tasks within the company, using front-end and back-end development technologies.
                </p>
            </div>
            <div className={styles.tldr}>
                <h3>TLDR;</h3>
                <ul className="arc3-typo-checklist">
                    <li>Cohesive team</li>
                    <li>Hella smort people</li>
                    <li>Nice new technologies</li>
                    <li>Office in the centre of Amsterdam</li>
                    <li>Freedom to grow and experiment</li>
                </ul>
            </div>
        </div>
    );
}
