import styles                  from './members.css';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper.scss';
import "swiper/components/navigation/navigation.min.css"
import "swiper/components/pagination/pagination.min.css"

// import Swiper core and required modules
import SwiperCore, {
    Navigation,Pagination
} from 'swiper/core';

// install Swiper modules
SwiperCore.use([Navigation,Pagination]);
import {Ed, Eef, RJ, Pim, Nina, Constant, Anatolii} from './members/index'

export default function Members(  ) {
    return (
        <div className={styles.teamSlider}>
            <Swiper
                spaceBetween={50}
                slidesPerView={1}
                navigation={true}
                loop={true}
                autoHeight={true}
                pagination={{
                    clickable: true
                }}
            >

                <SwiperSlide>
                    <Ed />
                </SwiperSlide>
                <SwiperSlide>
                    <Eef />
                </SwiperSlide>
                <SwiperSlide>
                    <RJ />
                </SwiperSlide>
                <SwiperSlide>
                    <Pim />
                </SwiperSlide>
                <SwiperSlide>
                    <Nina />
                </SwiperSlide>
                <SwiperSlide>
                    <Constant />
                </SwiperSlide>
                <SwiperSlide>
                    <Anatolii />
                </SwiperSlide>

            </Swiper>
        </div>

    );
}