import styles from './techstack.scss';


const techniques = [
    {
        alt: "PHP",
        url: "https://www.php.net/",
        image: "./assets/imgs/php.png"
    },
    {
        alt: "NodeJS",
        url: "https://nodejs.org/en/",
        image: "./assets/imgs/nodejs.png"
    },
    {
        alt: "TypeScript",
        url: "https://www.typescriptlang.org/",
        image: "./assets/imgs/typescript.png"
    },
    {
        alt: "Webpack",
        url: "https://webpack.js.org/",
        image: "./assets/imgs/webpack.png"
    },
    {
        alt: "AWS",
        url: "https://aws.amazon.com/",
        image: "./assets/imgs/aws.png"
    },
    {
        alt: "Docker",
        url: "https://www.docker.com/",
        image: "./assets/imgs/docker.png"
    },
    {
        alt: "Phrase",
        url: "https://phrase.com",
        image: "./assets/imgs/phrase.png"
    },
    {
        alt: "serverless",
        url: "https://serverless.com",
        image: "./assets/imgs/serverless.png"
    },
    {
        alt: "Terraform",
        url: "https://www.terraform.io/",
        image: "./assets/imgs/terraform.png"
    },
    {
        alt: "React",
        url: "https://reactjs.org/",
        image: "./assets/imgs/react.png"
    },
    {
        alt: "Github",
        url: "https://github.com",
        image: "./assets/imgs/github.png"
    },
    {
        alt: "Cypress",
        url: "https://www.cypress.io/",
        image: "./assets/imgs/cypress.png"
    },
    {
        alt: "Javascript",
        url: "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
        image: "./assets/imgs/js.png"
    },
    {
        alt: "Jenkins",
        url: "https://www.jenkins.io/",
        image: "./assets/imgs/jenkins.png"
    },
    {
        alt: "Stackoverflow",
        url: "https://stackoverflow.com/",
        image: "./assets/imgs/stackoverflow.png"
    },
    {
        alt: "Mariokart",
        image: "./assets/imgs/mariokart.png"
    }
]
export default function TechStack() {
    return (
        <div>
            <h2>Our techstack</h2>
            <div className={`${styles.techstack} arc3-flex-wrapper --justify-space-evenly --justify-content-space-between --flex-direction-row --align-items-center`}>
                {techniques.map( tech => <a className={styles.techstackItem} href={tech.url || '#'} target={tech.url ? '_blank' : '_self'}><img src={tech.image} alt={tech.alt} title={tech.alt} /></a> )}
            </div>
        </div>
    );
}
