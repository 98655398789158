import styles from '../members.css';
import image  from '../../../assets/imgs/pim.jpg';

export default function Pim ()
{
    return (
        <>
            <div className={ styles.memberSlide }>
                <div className={ 'arc3-flex-wrapper' }>
                    <div className={ `${ styles.businessCard } arc3-typo--text-center` }>
                        <img className={ styles.member } src={ image }/>
                        <h5>Pim</h5>
                    </div>
                    <div>
                        <p>
                            I am a software engineer in the Storefront team, together with the team I build features for the website. These features range from micro services to components for our component library

                            <br /><br />

                            It's nice being able to work on different parts of the system, since we maintain it from start to finish we get to touch a lot of different technologies and we get to experiment with a lot of new stuff.

                            <br /><br />

                            The central location of the office is great if you want to grab some lunch in the centre of Amsterdam. I also really appreciate the focus on personal development. The extravagant parties are also a nice bonus.

                            <br /><br />

                            I applied for an internship position and was subsequently hired after graduating at albelli. I am grateful I get to start my career in such an awesome team!
                        </p>
                    </div>

                </div>
            </div>
        </>
    );
}