import styles from '../members.css';
import image  from '../../../assets/imgs/nina.jpg';

export default function Nina ()
{
    return (
        <>
            <div className={ styles.memberSlide }>
                <div className={ 'arc3-flex-wrapper' }>
                    <div className={ `${ styles.businessCard } arc3-typo--text-center` }>
                        <img className={ styles.member } src={ image } />
                        <h5>Nina</h5>
                    </div>
                    <div>
                        <p>
                            Hi, I'm Nina! UX/UI designer at albelli, part-time Scrum Master for this team, and secretly an aspiring illustrator in my spare time. I enjoy simplifying complexity and, obviously, designing and creating stuff is my destiny so this job is a match made in heaven. I appreciate working in this team, among other albelli teams, because everyone is eager to learn and help each other out. Truly an admirable environment where you feel right at home!
                        </p>
                    </div>

                </div>
            </div>
        </>
    );
}