// import "./style/style.scss";
import "@albelli/arc-3/lib/core.css";
import "@albelli/arc-3/lib/icon.css";

import Header from "./components/header/header";
import Intro from "./components/intro/intro";
import AboutYou from "./components/aboutYou/aboutYou";
import Team from "./components/team/team";
import TechStack from "./components/techStack/techStack";
import Footer from "./components/footer";
import "./style/style.scss";

const App = () => {
    return (
        <>
            <Header />

            <div class="arc3-container arc3-padding--left-lg arc3-padding--right-lg">

                <Intro />

                <AboutYou />

                <Team />

                <TechStack />
            </div>

            <Footer />
        </>
    );
};

export default App;
