import styles from '../members.css';
import image  from '../../../assets/imgs/anatolii.jpg';

export default function Anatolii ()
{
    return (
        <>
            <div className={ styles.memberSlide }>
                <div className={ 'arc3-flex-wrapper' }>
                    <div className={ `${ styles.businessCard } arc3-typo--text-center` }>
                        <img className={ styles.member } src={ image }/>
                        <h5>Anatolii</h5>
                    </div>
                    <div>
                        <p>
                            Hi, I'm a software engineer from Ukraine. I love that this team is made up of friendly people who come from various different countries. Here I have a lot of flexibility in how to solve problems (what tech to use) or even what problems to solve. If I get tired of setting up the infra or developing the backend, I can go do some work on the frontend. And if I wanted to, I could try some other team roles as well. Albelli hires highly skilled people and allows them to develop their skills even further. And the Netherlands is an excellent place to work and live.
                        </p>
                    </div>

                </div>
            </div>
        </>
    );
}