import Members from "./members";
import Slider  from "./slider";
import styles from "./team.scss";
export default function Team() {
    return (
        <div>
            <h2>The Storefront team</h2>
            <div class="arc3-flex-wrapper">
                <div className={styles.picSlider}>
                    <Slider />
                </div>

                <div className={styles.teamText}>
                    <p>
                        In the Storefront team we value trust, collaboration and
                        honesty.
                    </p>

                    <p>
                        We take pride in the work we do and enjoy working
                        together to provide the best solution for our internal
                        and external customers.
                    </p>

                    <p>
                        We support each other's growth and value creative input
                        so we can do the best job possible. In our eyes a
                        project is a success when the stakeholders are happy,
                        the team has learned a new skill and our work provided
                        value.{" "}
                    </p>
                </div>
            </div>

            <Members />
        </div>
    );
}
