import styles from '../members.css';
import image  from '../../../assets/imgs/eduardo.jpg';

export default function Ed ()
{
    return (
        <>
            <div className={ styles.memberSlide }>
                <div className={ 'arc3-flex-wrapper' }>
                    <div className={ `${ styles.businessCard } arc3-typo--text-center` }>
                        <img className={ styles.member } src={ image }/>
                        <h5>Eduardo</h5>
                    </div>
                    <div>
                        <p>
                            Hi am Eduardo from Mexico, I have worked for Albelli for a bit more than 2 years as software engineer. I like working for Albeli as I have experienced how the company care for their employees, they give us support for our career, personal growth and health. Within Storefront I found myself since day one learning new and interesting things, building challenging customer face solutions and collaborating with a bunch of friendly and knowledgeable individuals while being able to experiment, have fun and have a good work-live balance. Looking backwards Albelli is the best company I have worked for so far.
                        </p>
                    </div>

                </div>
            </div>
        </>
    );
}