import styles from '../members.css';
import image  from '../../../assets/imgs/rj.jpg';

export default function RJ ()
{
    return (
        <>
            <div className={ styles.memberSlide }>
                <div className={ 'arc3-flex-wrapper' }>
                    <div className={ `${ styles.businessCard } arc3-typo--text-center` }>
                        <img className={ styles.member } src={ image } />
                        <h5>Robbert-Jan</h5>
                    </div>
                    <div>
                        <p>I'm Robbert-Jan or RJ, the product owner of the team. I protect the team from an overload of business request and balance long-term technical improvements vs short-term business value. I love that we improve the happiness of our colleagues by automating tedious, repetitive tasks. And that we get to improve the experience of users by making the site faster, more stable, more logical and more accurate.


                            And that a couple of people build this site on a chapter day!


                            I also very much like that everyone in the team has the drive to improve and is nice and friendly. The best parts of our team that you can benefit from:
                            <ul>
                                <li>You don’t have to support Internet Explorer 11 (do you need more reasons to join us?)</li>

                                <li>You can bring in improvements and ideas to work on</li>

                                <li>You get to own projects (if you want to)</li>

                                <li>You can get an answer about almost anything, because of the diverse background and skillsets of the team (we do well on company quizes)</li>

                                <li>You get a safe environment where you can share how you feel (so that we can improve things that are not going well)</li>

                                <li>You can improve your skills through chapter days and dev days once every 2 weeks</li>

                                <li>You get work with UX, CRO, marketing, data, QE, frontend & backend backgrounds in the team</li>

                                <li>You get to share achievements in our Friday wins</li>
                            </ul>


                            I applied here for my internship 10 years ago and never left, due to the cool stuff I get to contribute to, the growth opportunity albelli provides, the amazing culture and people, the rewarding feeling of producing nice products for our customers and the fact that you can make other people's life's better by automation, while doing things you love!
                        </p>
                    </div>

                </div>
            </div>
        </>
    );
}