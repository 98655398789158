import styles                  from '../members.css';
import image from '../../../assets/imgs/eef.jpg';
export default function Eef(  ) {
    return (
        <>
            <div className={styles.memberSlide}>
                <div className={'arc3-flex-wrapper'}>
                    <div className={`${styles.businessCard} arc3-typo--text-center`}>
                        <img className={styles.member} src={image} />
                        <h5>Eveline</h5>
                    </div>
                    <div>
                        <p>Hi there, I am a dutch native from Delft! I am a senior full stack engineer in the team. Next to front- and backend development, I am a sucker for devOps and CI/CD. I am a people pleaser, so I like to create things that make others happy. I like working in our team because we care (about the work and each other), respect each other, are continuously improving together and we are all happy to do the extra mile when it matters.
                            <br /><br />
                            I applied at albelli because we get the freedom and time to innovate and improve quality, something I was really missing before and I could not always deliver my best work because of time constraints.  I stayed at albelli because of the people I work with, the fun work environment and good benefits.</p>
                    </div>

                </div>
            </div>
        </>
    )
}